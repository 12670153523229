import { LogoHome, Register, Seo } from "components"
import Layout from "layout"
import React from "react"

const Registre = () => (
  <Layout>
    <Seo title="Registre-se" />
    <section id="register" className="d-flex justify-content-around">
      <Register />
      <LogoHome />
    </section>
  </Layout>
)

export default Registre
